import { Accordion, Paragraph } from "@okta/odyssey-react-mui";
import { Drawer } from "@okta/odyssey-react-mui/labs";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from 'react-router-dom';

const GuestManagementDrawer = ({isOpen = false, setOpen, section}) => {

    const {t} = useTranslation();

    return <Drawer
      ariaLabel="close"
      title={t('guest-management-help-title')}
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      variant="temporary"
    >
      <React.Fragment key=".0">
        <Accordion isDefaultExpanded={!section} label={t('guest-management-help-section-overview')}>
            <Paragraph>
                Guest access is granted via an allowlist that associates the guest with an Okta employee as you would do when signing a guest into an Okta building. This allows us to meet security and legal requirements as well as ensuring an Okta Employee is the nominated point of contact for any issues for that guest.
            </Paragraph>
        </Accordion>
        <Accordion isExpanded={!!section && section === 'grant' ? true : undefined} label={t('guest-management-help-section-invite')}>
            <Paragraph>
                The following process adds users to allowlist. This permits them to register or login using an existing account.
            </Paragraph>
            <Paragraph>
                <ol>
                    <li>Click ‘Grant guest access’</li>
                    <li>Click ‘Enter the email address of the guest’</li>
                    <li>Click ‘Select the sales opportunity to which the guest is related if you have one’</li>
                    <li>Click ‘Select the duration of access’</li>
                    <li>Click ‘Grant’</li>
                </ol>
            </Paragraph>
            <Paragraph>
                Your guest can now access demo.okta following the steps in Signing in as a Guest. This access is granted immediately and valid for 30 days.
            </Paragraph>
        </Accordion>
        <Accordion isExpanded={!!section && section === 'bulk'? true : undefined} label={t('guest-management-help-section-bulk')}>
            <Paragraph>
            For events such as marketing labs you may need to submit large number of guests quickly.
            </Paragraph>
            <Paragraph>
                <ol>
                    <li>Download the <RouterLink to='https://cdn.demo.okta.com/bulk-invite-template.csv'>bulk template file</RouterLink></li>
                    <li>Open the template file and enter the information for each your guests including guestEmail and accessLength</li>
                    <li>Click ‘Grant bulk access’</li>
                    <li>Upload your bulk file</li>
                    <li>Click ‘Grant guest access’</li>
                </ol>
            </Paragraph>
            <Paragraph>
                All of these users will now appear in the 'awaiting tab until they register. 
            </Paragraph>
        </Accordion>
      </React.Fragment>
    </Drawer>

}

export default GuestManagementDrawer;